import React from 'react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Location } from 'react-router-dom';
import './styles/index.scss';
import AuthenticationStore from '../../stores/Authentication';
import Translate from '../Translate/Translate';
import Alert, { Type } from '../Alert';

interface Props {
  authentication?: AuthenticationStore;
  location: Location;
}

@inject('authentication')
@observer
export default class SetPassword extends React.Component<Props> {
  @observable passwordReset = false;
  @observable token = '';
  @observable message: string;
  @observable tokenError = '';
  @observable passwordErrors;
  @observable errorsArea = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    runInAction(() => {
      this.token = token;
    });
  }

  handleValueChange(event) {
    const { authentication } = this.props;
    authentication.setValue(event.target.name, event.target.value);
  }

  async resetPassword() {
    const { authentication } = this.props;

    if (!authentication.setEmail) {
      this.setErrorMessage('email');
      return;
    } else if (
      !authentication.setEmail.includes('@') ||
      !authentication.setEmail.includes('.')
    ) {
      this.setErrorMessage('authemail');
      return;
    } else if (
      !authentication.setPassword &&
      !authentication.setPasswordConfirmation
    ) {
      this.setErrorMessage('password');
      return;
    } else {
      this.setErrorMessage('');
    }

    try {
      await authentication.resetPassword(this.token);
      this.passwordReset = true;
    } catch (error) {
      if (error.data.errors) {
        this.setErrors(error.data);
        this.errorsArea = true;
      }
      if (error.data.error) {
        this.setTokenError('password-fail');
        this.passwordErrors = '';
      }
      if (error.status === 500) {
        this.setTokenError('password-emailfail');
      }
    }
  }

  @action setErrors(response) {
    this.passwordErrors = response.errors.password;
  }

  @action setErrorMessage(message) {
    this.message = message;
  }

  @action setTokenError(message) {
    this.tokenError = message;
  }

  showErrorMessage() {
    let errorMessage;
    let passwordER;
    let passwordMessage;

    if (this.passwordErrors) {
      passwordER = this.passwordErrors.map((error, index) => {
        if (error === 'The password must be at least 6 characters.') {
          passwordMessage = 'passwordlength';
        } else if (error === 'The password confirmation does not match.') {
          passwordMessage = 'passwordmatch';
        } else {
          passwordMessage = '';
        }

        return (
          <p key={index}>
            <Translate component="login-error" keyword={passwordMessage} />
          </p>
        );
      });
    }

    if (this.message || this.errorsArea || this.tokenError) {
      errorMessage = (
        <Alert
          show={!!this.message || !!this.errorsArea || !!this.tokenError}
          type={Type.ERROR}
        >
          {passwordER}
          {this.tokenError ? (
            <Translate component="login-error" keyword={this.tokenError} />
          ) : null}

          {this.message ? (
            <Translate component="login-error" keyword={this.message} />
          ) : null}
        </Alert>
      );
    }
    return errorMessage;
  }

  render() {
    const { authentication } = this.props;

    return (
      <div className="component-login-setPassword">
        {this.passwordReset ? (
          <Alert show={!!this.passwordReset} type={Type.SUCCESS}>
            <Translate component="login-error" keyword={'password-success'} />
          </Alert>
        ) : (
          <React.Fragment>
            <h2>
              <Translate component="login" keyword="set-title" />
            </h2>
            <div className="component-login-setPassword__field">
              <label htmlFor="setEmail">
                <Translate component="login" keyword="email" />
              </label>
              <input
                type="text"
                name="setEmail"
                id="setEmail"
                onChange={this.handleValueChange}
                value={authentication.setEmail}
              />
              <span className="focus"></span>
            </div>

            <div className="component-login-setPassword__field">
              <label htmlFor="setPassword">
                <Translate component="login" keyword="password" />
              </label>
              <input
                type="password"
                name="setPassword"
                id="setPassword"
                onChange={this.handleValueChange}
                value={authentication.setPassword}
              />
              <span className="focus"></span>
            </div>

            <div className="component-login-setPassword__field">
              <label htmlFor="setPasswordConfirmation">
                <Translate component="login" keyword="password-again" />
              </label>
              <input
                type="password"
                name="setPasswordConfirmation"
                id="setPasswordConfirmation"
                onChange={this.handleValueChange}
                value={authentication.setPasswordConfirmation}
              />
              <span className="focus"></span>
            </div>
            {this.showErrorMessage()}
            <div
              className="component-login-setPassword__button"
              onClick={this.resetPassword}
            >
              <p>
                <Translate component="login" keyword="set-password" />
              </p>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
